<script>
import { userService } from '../../../helpers/backend/user.service';
/**
 * Transactions component
 */
export default {
  data() {
    return {
      data: [],
      loading : false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "ID", sortable: true, label: "User ID" },
        { key: "name", sortable: true, label: "Name" },
        { key: "username", sortable: true, label: "Username" },
        { key: "email", sortable: true, label: "Email" },
        { key: "phone_number", sortable: true, label: "Phone Number" },
        { key: "role_level", sortable: true, label: "Role" },
        { key: "action" }
      ],
      showDismissibleAlert : false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length;
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchData () {
      this.error = null;
      this.loading = true
      this.showDismissibleAlert=false
      //const fetchedId = this.$route.params.id
      userService.getAll().then(
        data=> {
          this.data = data.users;
          this.loading = false;
        }, 
        err => {
            this.error = "Failed to fetch machine data. Error : " + err + "  Dissmiss to refresh";
            this.loading = false;
            this.showDismissibleAlert=true
        }
      )
    },
    onDelete(id) {
      if(confirm("Do you really want to delete this user, this action can't be undone?")){
        userService.deleteById(id).then(
          data => {
            this.fetchData()
            console.log(data)
          },
          err => {
            this.error = "Failed to delete user. Error : " + err;
            this.showDismissibleAlert=true
          }
        )
      }
    },
    onUpdate(id) {
      this.$emit('onEdit',id);
    }
  }
};
</script>

<template>
<div>
  <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
       {{error}}
    </b-alert>
  <div class="card">
    <div class="card-body">
      <!-- <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <b-dropdown-item>Sales Report</b-dropdown-item>
        <b-dropdown-item>Export Report</b-dropdown-item>
        <b-dropdown-item>Profit</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
      </b-dropdown> -->

      <h4 class="card-title mb-4">User</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="data"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(role_level)="row">
            <div
              class="badge font-size-12 badge-success"
            >{{ row.item.role_level == 3 ? "Owner" : row.item.role_level == 2 ? "Manager" :"Staff"}}</div>
          </template>

          <template v-slot:cell(action)="row">
            <a
              @click="onUpdate(row.item.ID)"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
            >
              <i class="mdi mdi-pencil font-size-18"></i>
            </a>
            <a @click="onDelete(row.item.ID)" class="text-danger" v-b-tooltip.hover title="Delete">
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>