<script>
import { userService } from '../../../helpers/backend/user.service';
//import { userService } from '../../../helpers/backend/user.service';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  export default {
    components : {
      VuePhoneNumberInput
    },
    data() {
      return {
        form: {
          id : null,
          name: '',
          username: '',
          password: "",
          email: '',
          phone_number: "",
          role_level: null,
        },
        role_options : [{text:'Staff',value : 1}, {text:'Manager',value : 2},{text:'Owner',value : 3}],
        busy : false,
        
        show: true,
        showDismissibleAlert : false,
        error : "",
        renderComponent: true,
      }
    },
    created(){
      this.fetchData();
    },
    computed: {
      isUpdateState() {
        return this.form.id != null;
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.busy = true;
        userService.createOrUpdate(this.form).then (
          data=> {
              if (data != null && data.user != null) {
                this.reset();
                      this.$emit('refresh');
                      this.showDismissibleAlert =  true
                      this.error = "";
                      this.busy = false;
                
              }else {
                      this.showDismissibleAlert =  true
                      this.error = "Something wrong";
                      this.busy = false;
              }
          }, 
          error=> {
              this.showDismissibleAlert =  true
              this.error = "Failed to Insert/Update User. Error : " + error
              this.busy = false;
          }
        )
      },
      reset(){
        // Reset our form values
        this.form.id = null
        this.form.name = ''
        this.form.username = ''
        this.form.password = ''
        this.form.email = ''
        this.form.phone_number = ''
        this.form.role_level = null
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.showDismissibleAlert = false
        this.error = ""
        this.$nextTick(() => {
          this.show = true
        })
      },
      onReset(event) {
        event.preventDefault()
        this.reset();
      },
      initUpdate(id){
        this.reset();
        this.$refs.name.$el.focus();
        this.busy = true;
        userService.getById(id).then(
          data=> {
            if (data.user != null) {
                  var payload = data.user;
                  this.form.id = payload.ID
                  this.form.name = payload.name
                  this.form.username = payload.username
                  this.form.email = payload.email
                  this.form.phone_number = payload.phone_number
                  this.form.role_level = payload.role_level
            }else {
                this.showDismissibleAlert = true
                this.error = "Failed to get machine to update. Error : Machine not found"
            }
            this.busy = false;
          }, 
          err=> {
            this.showDismissibleAlert = true
            this.error = "Failed to get machine to update. Error : " + err
            this.busy = false;
          }
        )
      },
      fetchData(){
        }
    }
  }
</script>


<template>
<div>
  <b-alert
      :variant="error=='' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert=false"
    >
      <div v-if="error != ''">
        {{error}}
      </div>
      <div v-else>
          Success perform Insert/Update action
        </div>
    </b-alert>
  <div class="card">
    <div class="card-body">
      <div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
      <b-overlay :show="busy" rounded="lg" opacity="0.6">
      <b-form-group
        id="input-group-1"
        label="Name:"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.name"
          type="text"
          placeholder="Enter Name"
          required
          ref="name"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-2"
        label="Username:"
        label-for="input-2"
      >
        <b-form-input
          id="input-2"
          v-model="form.username"
          type="text"
          placeholder="Enter Username"
          required
          ref="username"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-3"
        label="Email:"
        label-for="input-3"
      >
        <b-form-input
          id="input-3"
          v-model="form.email"
          type="email"
          placeholder="Enter Email"
          required
          ref="email"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="Password:" label-for="input-4">
        <b-form-input
          id="input-4"
          v-model="form.password"
          placeholder="Enter password"
          type="password"
          :required="form.id == null ? true : false"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-5"
        label="Phone number:"
        label-for="input-5"
      >
      <VuePhoneNumberInput 
          id="input-5" 
          required
          :only-countries="['ID']"
          :default-country-code="'ID'"
          v-model="form.phone_number" />
        
      </b-form-group>
      
      <b-form-group id="input-group-6" label="Role level:" label-for="input-6">
        <b-form-select
          id="input-6"
          v-model="form.role_level"
          :options="role_options"
          required
        ></b-form-select>
      </b-form-group>

      <b-button type="submit" variant="primary">{{isUpdateState ? "Update" : "Submit"}}</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
      </b-overlay>
    </b-form>
    
  </div>
    </div>
  </div>
</div>
</template>



